import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#fff"
        d="M15.836 6.667l-3.333 3.334h2.5c0 2.758-2.242 5-5 5a4.892 4.892 0 01-2.334-.584l-1.216 1.217a6.609 6.609 0 003.55 1.033 6.665 6.665 0 006.666-6.666h2.5l-3.333-3.334zM5.003 10.001c0-2.759 2.241-5 5-5 .841 0 1.641.208 2.333.583l1.217-1.217a6.61 6.61 0 00-3.55-1.033 6.665 6.665 0 00-6.667 6.667h-2.5l3.333 3.333 3.334-3.333h-2.5z"
      />
    </Svg>
  )
}

export default Icon
