import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.93 11.215l-4.715 5.5-4.714-5.5M11.219 16.715v-11"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.215 1C5.575 1 1.001 5.573 1.001 11.214c0 5.642 4.573 10.215 10.214 10.215 5.642 0 10.215-4.573 10.215-10.215C21.43 5.574 16.857 1 11.215 1z"
      />
    </Svg>
  )
}

export default Icon
