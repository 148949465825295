import { Price } from '@beraswap-dex/sdk'
import { Text, AutoRenewIcon, Flex } from 'packages/uikit'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `1 ${price?.quoteCurrency?.symbol} = ${price?.baseCurrency?.symbol}`
    : `1 ${price?.baseCurrency?.symbol} = ${price?.quoteCurrency?.symbol}`

  return (
    <Text style={{ alignItems: 'center', display: 'flex', color: 'white', width: '100%' }}>
      {show ? (
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Text color="white">Price</Text>
          <Flex>
            {label} {formattedPrice ?? '-'}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <AutoRenewIcon width="20px" />
            </StyledBalanceMaxMini>
          </Flex>
        </Flex>
      ) : (
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Text color="white">Price:</Text>
          <Text color="white">---</Text>
        </Flex>
      )}
    </Text>
  )
}
