import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_2647_8090)">
        <path
          d="M0.710938 7.14369L4.28237 3.57227L7.85379 7.14369"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.56696 19.2866H5.70982C5.33094 19.2866 4.96758 19.136 4.69966 18.8681C4.43176 18.6003 4.28125 18.2368 4.28125 17.858V3.57227"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2835 12.8574L15.7121 16.4289L12.1406 12.8574"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4219 0.714844H14.279C14.6579 0.714844 15.0213 0.865354 15.2892 1.13326C15.557 1.40117 15.7076 1.76453 15.7076 2.14342V16.4291"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2647_8090">
          <rect width="20" height="20" fill="black" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
