import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrencyAmount, JSBI, Token, Trade, TradeType, ETHER } from '@beraswap-dex/sdk'

import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  IconButton,
  // BottomDrawer,
  useMatchBreakpoints,
  // ArrowUpDownIcon,
  Skeleton,
  Switch,
  ChevronDownIcon,
  ChevronUpIcon,
} from 'packages/uikit'
import { useIsTransactionUnsupported } from 'hooks/Trades'
// import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import Footer from 'components/Menu/Footer'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning'
import { useGetUserInfo } from 'views/LeaderBoard/hooks'
// import { useAppDispatch } from 'state'
// import { fetchUserLeaderBoardAsync } from 'state/leaderBoard/reducer'
// import { API_URL } from 'state/leaderBoard/constants'
// import { createData } from 'state/leaderBoard/helpers'
import useRefreshBlockNumberID from './hooks/useRefreshBlockNumber'
import AddressInputPanel from './components/AddressInputPanel'
import { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Layout/Column'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween, RowFixed } from '../../components/Layout/Row'
// import AdvancedSwapDetailsDropdown from './components/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from './components/confirmPriceImpactWithoutFee'
import { ArrowWrapper, SwapCallbackError, Wrapper } from './components/styleds'
import TradePrice from './components/TradePrice'
import ProgressSteps from './components/ProgressSteps'
import { AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'

import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  useSingleTokenSwapInfo,
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager,
} from '../../state/user/hooks'
import CircleLoader from '../../components/Loader/CircleLoader'
import Page from '../Page'
import SwapWarningModal from './components/SwapWarningModal'
import PriceChartContainer from './components/Chart/PriceChartContainer'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import CurrencyInputHeader from './components/CurrencyInputHeader'
import ImportTokenWarningModal from '../../components/ImportTokenWarningModal'
import FormattedPriceImpact from './components/FormattedPriceImpact'
import SwapRoute from './components/SwapRoute'

const Label = styled(Text).attrs({ fontSize: '16px', color: 'white' })``

const SwitchIconButton = styled(IconButton)`
  --space: 0;
  /* box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.primaryBright};
  padding: 20px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin: auto;
  }

  .icon-down {
    transform: rotate(0deg);
    transition: all 0.25s linear;
  }

  &:hover {
    .icon-down {
      transform: rotate(180deg);
    }
  }
`

const StyledPrice = styled(Flex)<{ $isMobile?: boolean }>`
  padding: 8px 10px;
  background: transparent;
`

const Info = styled(Flex)<{ $isMobile?: boolean }>`
  background: #383838;
  padding: 15px 20px;
  border-radius: 8px;
`

export default function Swap() {
  const router = useRouter()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const [isChartExpanded, setIsChartExpanded] = useState(false)
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()
  const [isShow, setIsShow] = useState(false)
  // const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()
  // const userInfo = useGetUserInfo()
  // const userInfoLeaderBoard = userInfo?.userInfo

  // useEffect(() => {
  //   dispatch(fetchUserLeaderBoardAsync(account))
  // }, [dispatch, account])

  const showPrice = () => {
    setIsShow(!isShow)
  }

  useEffect(() => {
    setUserChartPreference(isChartDisplayed)
  }, [isChartDisplayed, setUserChartPreference])

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens)
    })

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)) {
      return
    }
    if (!swapCallback) {
      return
    }

    setSwapState({ attemptingTxn: true, tradeToConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(async (hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [priceImpactWithoutFee, t, swapCallback, tradeToConfirm])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn })
  }, [attemptingTxn, swapErrorMessage, trade, txHash])

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)
  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />, false)

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency])

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput)
      const showSwapWarning = shouldShowSwapWarning(currencyInput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput)
      } else {
        setSwapWarningCurrency(null)
      }
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput)
      const showSwapWarning = shouldShowSwapWarning(currencyOutput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyOutput)
      } else {
        setSwapWarningCurrency(null)
      }
    },

    [onCurrencySelection],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => router.push('/swap')} />,
  )

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length])

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  const hasAmount = Boolean(parsedAmount)

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const isExactIn = trade?.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { realizedLPFee } = computeTradePriceBreakdown(trade)

  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <Flex width="100%" position="relative">
        <Flex flexDirection="column">
          <StyledSwapContainer $isChartExpanded={isChartExpanded}>
            <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
              <AppBody>
                <CurrencyInputHeader
                  title={t('Swap')}
                  subtitle={t('')}
                  setIsChartDisplayed={setIsChartDisplayed}
                  isChartDisplayed={isChartDisplayed}
                  hasAmount={hasAmount}
                  onRefreshPrice={onRefreshPrice}
                />
                <Wrapper id="swap-page">
                  <AutoColumn>
                    <CurrencyInputPanel
                      label={independentField === Field.OUTPUT && !showWrap && trade ? t('From') : t('Swap From:')}
                      value={formattedAmounts[Field.INPUT]}
                      showMaxButton={!atMaxAmountInput}
                      currency={currencies[Field.INPUT]}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      onCurrencySelect={handleInputSelect}
                      otherCurrency={currencies[Field.OUTPUT]}
                      id="swap-currency-input"
                      showCommonBases
                    />

                    <AutoColumn justify="space-between">
                      <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                        {!isMobile ? (
                          <SwitchIconButton
                            variant="light"
                            scale="sm"
                            onClick={() => {
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                            }}
                          >
                            <Switch width="20px" className="icon-down" />
                          </SwitchIconButton>
                        ) : null}

                        {recipient === null && !showWrap && isExpertMode ? (
                          <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                            <Text>{t('+ Add a send (optional)')}</Text>
                          </Button>
                        ) : null}
                      </AutoRow>
                    </AutoColumn>

                    <CurrencyInputPanel
                      value={formattedAmounts[Field.OUTPUT]}
                      onUserInput={handleTypeOutput}
                      label={independentField === Field.INPUT && !showWrap && trade ? t('Swap To') : t('Swap To:')}
                      showMaxButton={false}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={handleOutputSelect}
                      otherCurrency={currencies[Field.INPUT]}
                      id="swap-currency-output"
                      showCommonBases
                    />

                    {isExpertMode && recipient !== null && !showWrap ? (
                      <>
                        <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                          <ArrowWrapper clickable={false}>
                            <ArrowDownIcon width="16px" />
                          </ArrowWrapper>
                          <Button variant="text" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                            <Text>{t('- Remove send')}</Text>
                          </Button>
                        </AutoRow>
                        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                      </>
                    ) : null}

                    <StyledPrice $isMobile={isMobile} onClick={showPrice} width="100%" justifyContent="space-between">
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                      {/* {isShow ? <ChevronUpIcon color="#E81899" /> : <ChevronDownIcon color="#E81899" />} */}
                    </StyledPrice>

                    {/* {showWrap ? null : (
                      <AutoColumn gap="7px">
                        <RowBetween align="center">
                          {Boolean(trade) && (
                            <>
                              {isLoading ? (
                                <Skeleton width="100%" ml="8px" height="24px" />
                              ) : (
                                <Flex style={{ gap: '10px' }} width="100%" flexDirection="column">
                                  <StyledPrice
                                    $isMobile={isMobile}
                                    onClick={showPrice}
                                    width="100%"
                                    justifyContent="space-between"
                                  >
                                    <TradePrice
                                      price={trade?.executionPrice}
                                      showInverted={showInverted}
                                      setShowInverted={setShowInverted}
                                    />
                                    {isShow ? <ChevronUpIcon color="#E81899" /> : <ChevronDownIcon color="#E81899" />}
                                  </StyledPrice>

                                  {isShow && (
                                    <StyledPrice $isMobile={isMobile} style={{ flexDirection: 'column' }}>
                                      <RowBetween>
                                        <RowFixed>
                                          <Label fontSize="14px" color="#E81899">
                                            {isExactIn ? t('Min. Received') : t('Maximum sold')}
                                          </Label>
                                        </RowFixed>
                                        <RowFixed>
                                          <Text color="#E81899" fontSize="14px">
                                            {isExactIn
                                              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                                                  trade?.outputAmount.currency.symbol
                                                }` ?? '-'
                                              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                                                  trade?.inputAmount.currency.symbol
                                                }` ?? '-'}
                                          </Text>
                                        </RowFixed>
                                      </RowBetween>

                                      <RowBetween align="center">
                                        <Label>{t('Max. Slippage')}</Label>
                                        <Text bold color="#E81899">
                                          {allowedSlippage / 100}%
                                        </Text>
                                      </RowBetween>

                                      <RowBetween>
                                        <RowFixed>
                                          <Label fontSize="14px" color="#E81899">
                                            {t('Price Impact')}
                                          </Label>
                                        </RowFixed>
                                        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                                      </RowBetween>

                                      <RowBetween>
                                        <RowFixed>
                                          <Label fontSize="14px" color="#E81899">
                                            {t('Liquidity Provider Fee')}
                                          </Label>
                                        </RowFixed>
                                        <Text color="#E81899" fontSize="14px">
                                          {realizedLPFee
                                            ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}`
                                            : '-'}
                                        </Text>
                                      </RowBetween>

                                      <RowBetween>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                          <Label fontSize="14px" color="#E81899">
                                            {t('Route')}
                                          </Label>
                                        </span>
                                        <SwapRoute trade={trade} />
                                      </RowBetween>
                                    </StyledPrice>
                                  )}
                                </Flex>
                              )}
                            </>
                          )}
                        </RowBetween>
                      </AutoColumn>
                    )} */}
                  </AutoColumn>
                  <Box mt="0.25rem">
                    {swapIsUnsupported ? (
                      <Button width="100%" disabled>
                        {t('Unsupported Asset')}
                      </Button>
                    ) : !account ? (
                      <ConnectWalletButton
                        style={{
                          // width: isMobile ? '100%' : '48%',
                          width: isMobile ? '100%' : '100%',
                        }}
                      />
                    ) : showWrap ? (
                      <Button width="100%" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                        {wrapInputError ??
                          (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                      </Button>
                    ) : noRoute && userHasSpecifiedInputOutput ? (
                      <Button disabled width="100%" style={{ padding: '0.75rem' }}>
                        <Text>{t('Insufficient liquidity for this trade.')}</Text>
                        {singleHopOnly && <Text>{t('Try enabling multi-hop trades.')}</Text>}
                      </Button>
                    ) : showApproveFlow ? (
                      <RowBetween>
                        <Button
                          variant={approval === ApprovalState.APPROVED ? 'pink' : 'pink'}
                          onClick={approveCallback}
                          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                          style={{
                            // width: isMobile ? '100%' : '48%',
                            width: isMobile ? '100%' : '100%',
                          }}
                        >
                          {approval === ApprovalState.PENDING ? (
                            <AutoRow gap="6px" justify="center">
                              {t('Enabling')} <CircleLoader stroke="white" />
                            </AutoRow>
                          ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                            t('Enabled')
                          ) : (
                            t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                          )}
                        </Button>
                        <Button
                          // variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                          variant="primary"
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                txHash: undefined,
                              })
                              onPresentConfirmModal()
                            }
                          }}
                          style={{
                            // width: isMobile ? '100%' : '48%',
                            width: isMobile ? '100%' : '100%',
                          }}
                          id="swap-button"
                          disabled={
                            !isValid ||
                            approval !== ApprovalState.APPROVED ||
                            (priceImpactSeverity > 3 && !isExpertMode)
                          }
                        >
                          {priceImpactSeverity > 3 && !isExpertMode
                            ? t('Price Impact High')
                            : priceImpactSeverity > 2
                            ? t('Swap Anyway')
                            : t('Swap')}
                        </Button>
                      </RowBetween>
                    ) : (
                      <Button
                        variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'yellow' : 'yellow'}
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              txHash: undefined,
                            })
                            onPresentConfirmModal()
                          }
                        }}
                        id="swap-button"
                        style={{
                          // width: isMobile ? '100%' : '48%',
                          width: isMobile ? '100%' : '100%',
                        }}
                        disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                      >
                        {swapInputError ||
                          (priceImpactSeverity > 3 && !isExpertMode
                            ? t('Price Impact Too High')
                            : priceImpactSeverity > 2
                            ? t('Swap Anyway')
                            : t('Swap'))}
                      </Button>
                    )}
                    {showApproveFlow && (
                      <Column style={{ marginTop: '1rem' }}>
                        <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                      </Column>
                    )}
                    {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                  </Box>

                  {Boolean(trade) && (
                    <Info mt="20px" $isMobile={isMobile} style={{ flexDirection: 'column' }}>
                      <RowBetween>
                        <RowFixed>
                          <Label>{isExactIn ? t('Min. Received') : t('Maximum sold')}</Label>
                        </RowFixed>
                        <RowFixed>
                          <Text color="deepGreen" fontSize="16px">
                            {isExactIn
                              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                                  trade?.outputAmount.currency.symbol
                                }` ?? '-'
                              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                                  trade?.inputAmount.currency.symbol
                                }` ?? '-'}
                          </Text>
                        </RowFixed>
                      </RowBetween>

                      <RowBetween align="center">
                        <Label>{t('Max. Slippage')}</Label>
                        <Text color="deepGreen" fontSize="16px">
                          {allowedSlippage / 100}%
                        </Text>
                      </RowBetween>

                      <RowBetween>
                        <RowFixed>
                          <Label>{t('Price Impact')}</Label>
                        </RowFixed>
                        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                      </RowBetween>

                      <RowBetween>
                        <RowFixed>
                          <Label>{t('Liquidity Provider Fee')}</Label>
                        </RowFixed>
                        <Text color="deepGreen" fontSize="16px">
                          {realizedLPFee
                            ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}`
                            : '-'}
                        </Text>
                      </RowBetween>

                      <RowBetween>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <Label>{t('Route')}</Label>
                        </span>
                        <SwapRoute trade={trade} />
                      </RowBetween>
                    </Info>
                  )}
                </Wrapper>
              </AppBody>
              {/* {!swapIsUnsupported ? (
                trade && <AdvancedSwapDetailsDropdown trade={trade} />
              ) : (
                <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
              )} */}
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
          {isChartExpanded && (
            <Box display={['none', null, null, 'block']} width="100%" height="100%">
              <Footer variant="side" helpUrl={EXCHANGE_DOCS_URLS} />
            </Box>
          )}
        </Flex>
      </Flex>
    </Page>
  )
}
